<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				<slot name="title" />
			</template>
			<template #body>
				<div class="cassie-vertical-md">
					<DataTable
						:items="formattedPermissions"
						:headers="tableHeaders"
						:items-per-page="-1"
						:hide-default-footer="true"
						:item-class="rowClass"
						fixed-header
						height="60vh"
					>
						<template #item.page="{ item }">
							<span
								:style="{ fontWeight: item.level === 1 || item.isSpecial ? 'bold' : 'normal', marginLeft: item.level * 20 + 'px', textWrap: 'nowrap' }"
							>
								{{ item.page }}
							</span>
						</template>
						<template #item.fullPermission="{ item }">
							<v-switch
								v-if="item.fullPermission"
								:input-value="selectedPermissions.includes(item.fullPermission)"
								:disabled="readonly"
								@change="updateSelectedPermissions(item.fullPermission, item.parentId, item.pageId, 'fullPermission', item.isSpecial)"
							/>
						</template>
						<template #item.read="{ item }">
							<v-switch
								v-if="item.read"
								:input-value="selectedPermissions.includes(item.read)"
								:disabled="readonly"
								@change="updateSelectedPermissions(item.read, item.parentId, item.pageId, 'read', item.isSpecial)"
							/>
						</template>
						<template #item.createAndEdit="{ item }">
							<v-switch
								v-if="item.createAndEdit"
								:input-value="selectedPermissions.includes(item.createAndEdit)"
								:disabled="readonly"
								@change="updateSelectedPermissions(item.createAndEdit, item.parentId, item.pageId, 'createAndEdit', item.isSpecial)"
							/>
						</template>
						<template #item.delete="{ item }">
							<v-switch
								v-if="item.delete"
								:input-value="selectedPermissions.includes(item.delete)"
								:disabled="readonly"
								@change="updateSelectedPermissions(item.delete, item.parentId, item.pageId, 'delete', item.isSpecial)"
							/>
						</template>
					</DataTable>
				</div>
			</template>
		</sectioncard>
	</div>
</template>

<script>

import { getAdminPortalPermissions } from '../utils/api/product-roles.js'
import SectionCard from './section-card.vue'
import DataTable from './data-table.vue'

const defaultAccessTypes = ['Full Permission', 'Read', 'Create and Edit', 'Delete']

export default {
	components: {
		SectionCard,
		DataTable
	},
	props: {
		permissions: {
			type: Array,
			default: () => []
		},
		readonly: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			userRoleName: '',
			adminPortalPermissions: [],
			tableHeaders: [
				{ text: 'Permission', value: 'page', sortable: false },
				{ text: 'Full Permissions', value: 'fullPermission', sortable: false },
				{ text: 'Read', value: 'read', sortable: false },
				{ text: 'Create and Edit', value: 'createAndEdit', sortable: false },
				{ text: 'Delete', value: 'delete', sortable: false }
			],
			selectedPermissions: [],
			accessTypes: {
				FULL_PERMISSION: 'Full Permission',
				READ: 'Read',
				CREATE_AND_EDIT: 'Create and Edit',
				DELETE: 'Delete'
			}
		}
	},
	computed: {
		userRole () {
			return {
				name: this.userRoleName,
				permissions: this.selectedPermissions
			}
		},
		sortedPermissions () {
			const permissions = this.adminPortalPermissions.slice(0).sort((a, b) => {
				if (a.parentId === b.parentId) {
					return a.displayOrder - b.displayOrder // Ascending order for parents
				}
				return a.parentId - b.parentId
			})

			const permissionMap = new Map()
			const result = []

			for (const permission of permissions) {
				permissionMap.set(permission.id, permission)
			}

			// Ensure items are placed next to their parents in the array
			for (const permission of permissions) {
				if (!permission.parentId) {
					result.push(permission)
				}
			}

			// Sort children of level 2 by displayOrder in descending order
			const level2Children = permissions.filter(permission => permission.parentId && permission.level === 2)
			level2Children.sort((a, b) => b.displayOrder - a.displayOrder)
			for (const permission of level2Children) {
				const parentPermission = permissionMap.get(permission.parentId)
				if (parentPermission) {
					const parentIndex = result.indexOf(parentPermission)
					result.splice(parentIndex + 1, 0, permission)
				}
			}

			// Sort children of level 3 by displayOrder in descending order
			const level3Children = permissions.filter(permission => permission.parentId && permission.level === 3)
			level3Children.sort((a, b) => b.displayOrder - a.displayOrder)
			for (const permission of level3Children) {
				const parentPermission = permissionMap.get(permission.parentId)
				if (parentPermission) {
					const parentIndex = result.indexOf(parentPermission)
					result.splice(parentIndex + 1, 0, permission)
				}
			}

			return result
		},
		isItemSelected (id) {
			return this.selectedPermissions.includes(id)
		},
		formattedPermissions () {
			const formatted = this.sortedPermissions?.map(permission => {
				return {
					pageId: permission.id,
					page: permission.page,
					level: permission.level,
					parentId: permission.parentId,
					fullPermission: permission.accessTypes.find(accessType => accessType.accessType === this.accessTypes.FULL_PERMISSION)?.permissionId,
					read: permission.accessTypes.find(accessType => accessType.accessType === this.accessTypes.READ)?.permissionId,
					createAndEdit: permission.accessTypes.find(accessType => accessType.accessType === this.accessTypes.CREATE_AND_EDIT)?.permissionId,
					delete: permission.accessTypes.find(accessType => accessType.accessType === this.accessTypes.DELETE)?.permissionId
				}
			}).flat().map(
				(item, index) => {
					return {
						...item,
						id: index
					}
				}
			)
			this.sortedPermissions.filter(permission => !permission.accessTypes.every(item => defaultAccessTypes.includes(item.accessType))).forEach(permission => {
				const specialPermissions = permission.accessTypes.filter(accessType => !defaultAccessTypes.includes(accessType.accessType))
				specialPermissions.forEach(specialPermission => {
					const parentPermission = formatted.find(item => item.pageId === permission.id)
					const parentIndex = formatted.indexOf(parentPermission)
					formatted.splice(parentIndex, 0, { // Insert before the parent permission
						pageId: permission.id,
						page: 'Special Permission - ' + permission.page + ' - ' + specialPermission.permissionDescription,
						level: permission.level,
						parentId: permission.parentId,
						fullPermission: specialPermission.permissionId,
						isSpecial: true
					})
				})
			})
			const formattedPermissionsWithSeparators = []

			for (const item of formatted) {
				if (item.id !== 0 && !item.parentId && item.pageId !== formatted[formatted.indexOf(item) - 1]?.pageId) {
					formattedPermissionsWithSeparators.push({
						separator: true,
						parentId: null
					})
				}
				formattedPermissionsWithSeparators.push(item)
			}
			return formattedPermissionsWithSeparators
		}
	},
	async created () {
		this.loadAdminPortalPermissions()
		this.selectedPermissions = this.permissions
	},
	methods: {
		async loadAdminPortalPermissions () {
			const { data: adminPortalPermissions } = await getAdminPortalPermissions()
			this.adminPortalPermissions = adminPortalPermissions
		},
		updateSelectedPermissions (permissionId, parentId, pageId, accessType, isSpecial) {
			// Update the selected permission on/off
			const toggleOn = !this.selectedPermissions.includes(permissionId)
			if (toggleOn) {
				this.selectedPermissions.push(permissionId)
			} else {
				this.selectedPermissions = this.selectedPermissions.filter(id => id !== permissionId)
			}
			// Almost all toggle actions will have effects on related permissions
			const children = this.formattedPermissions.filter(permission => permission.parentId === pageId)
			if (toggleOn)	{
				children.forEach(child => {
					// Apply effects to child permissions
					let accessTypeIds = []
					switch (accessType) {
						case 'fullPermission':
							accessTypeIds = [child.fullPermission, child.read, child.createAndEdit, child.delete].filter(id => id !== undefined)
							break
						case 'read':
							accessTypeIds = [child.read].filter(id => id !== undefined)
							break
						case 'createAndEdit':
							accessTypeIds = [child.read, child.createAndEdit].filter(id => id !== undefined)
							break
						case 'delete':
							accessTypeIds = [child.read, child.delete].filter(id => id !== undefined)
							break
						default:
							break
					}
					accessTypeIds.forEach(id =>	{
						if (id != null) this.selectedPermissions.push(id)
					})
					// Apply effects to grandchild permissions
					const grandchildren = this.formattedPermissions.filter(permission => permission.parentId === child.pageId)
					grandchildren.forEach(grandchild => {
						switch (accessType) {
							case 'fullPermission':
								accessTypeIds = [grandchild.fullPermission, grandchild.read, grandchild.createAndEdit, grandchild.delete].filter(id => id !== undefined)
								break
							case 'read':
								accessTypeIds = [grandchild.read].filter(id => id !== undefined)
								break
							case 'createAndEdit':
								accessTypeIds = [grandchild.read, grandchild.createAndEdit].filter(id => id !== undefined)
								break
							case 'delete':
								accessTypeIds = [grandchild.read, grandchild.delete].filter(id => id !== undefined)
								break
							default:
								break
						}
						accessTypeIds.forEach(id =>	{
							if (id != null) this.selectedPermissions.push(id)
						})
					})
				})
				// Minimum of 'read' should be selected for a parent if any children are toggled on
				const thisPermission = this.formattedPermissions.find(permission => permission.pageId === pageId)
				const parent = this.formattedPermissions.find(permission => permission.pageId === thisPermission.parentId)
				if (parent != null)	{
					if (parent.read) this.selectedPermissions.push(parent.read)
					const grandParent = this.formattedPermissions.find(permission => permission.pageId === parent.parentId)
					if (grandParent?.read) this.selectedPermissions.push(grandParent.read)
				}
				// Toggle all sibling permissions if Full Permission selected for a page
				if (accessType === 'fullPermission' && !isSpecial)	{
					const siblingPermissions = this.formattedPermissions.filter(permission => permission.pageId === pageId)
					siblingPermissions.forEach(siblingPermission => {
						const accessTypeIds = [siblingPermission.read, siblingPermission.createAndEdit, siblingPermission.delete]
						accessTypeIds.forEach(id =>	{
							if (id != null) this.selectedPermissions.push(id)
						})
					})
				}
				// We also want to make sure the Read toggle for the same page is ON when any other permission selected
				if (accessType !== 'read' && (accessType !== 'fullPermission' || isSpecial))	{
					const siblingReadPermission = this.formattedPermissions.find(permission => permission.pageId === pageId && permission.read)
					if (siblingReadPermission?.read != null && this.selectedPermissions.find(permission => permission === siblingReadPermission?.read) == null)	{
						this.selectedPermissions.push(siblingReadPermission.read)
					}
				}
			}
			// Behaviour for deselecting a toggle
			if (!toggleOn)	{
				children.forEach(child => {
					let accessTypeIds = []
					switch (accessType) {
						case 'fullPermission':
							accessTypeIds = [child.fullPermission, child.createAndEdit, child.delete].filter(id => id !== undefined)
							break
						case 'read':
							accessTypeIds = [child.fullPermission, child.read, child.createAndEdit, child.delete].filter(id => id !== undefined)
							break
						case 'createAndEdit':
							accessTypeIds = [child.fullPermission, child.createAndEdit].filter(id => id !== undefined)
							break
						case 'delete':
							accessTypeIds = [child.fullPermission, child.delete].filter(id => id !== undefined)
							break
						default:
							break
					}
					accessTypeIds.forEach(id =>	{
						if (id != null) this.selectedPermissions = this.selectedPermissions.filter(permission => permission !== id)
					})
					const grandchildren = this.formattedPermissions.filter(permission => permission.parentId === child.pageId)
					grandchildren.forEach(grandchild => {
						switch (accessType) {
							case 'fullPermission':
								accessTypeIds = [grandchild.fullPermission, grandchild.createAndEdit, grandchild.delete].filter(id => id !== undefined)
								break
							case 'read':
								accessTypeIds = [grandchild.fullPermission, grandchild.read, grandchild.createAndEdit, grandchild.delete].filter(id => id !== undefined)
								break
							case 'createAndEdit':
								accessTypeIds = [grandchild.fullPermission, grandchild.createAndEdit].filter(id => id !== undefined)
								break
							case 'delete':
								accessTypeIds = [grandchild.fullPermission, grandchild.delete].filter(id => id !== undefined)
								break
							default:
								break
						}
						accessTypeIds.forEach(id =>	{
							if (id != null) this.selectedPermissions = this.selectedPermissions.filter(permission => permission !== id)
						})
					})
				})
				// Full Permission should be deselected for a parent if any children are toggled off
				const parent = this.formattedPermissions.find(permission => permission.pageId === parentId)
				if (parent != null)	{
					if (parent.fullPermission) {
						this.selectedPermissions = this.selectedPermissions.filter(permission => permission !== parent.fullPermission)
					}
					const grandParent = this.formattedPermissions.find(permission => permission.pageId === parent.parentId)
					if (grandParent?.fullPermission) this.selectedPermissions = this.selectedPermissions.filter(permission => permission !== grandParent.fullPermission)
				}
				// We also want to make sure all other toggles are off on the same page when Read Permission is deselected
				if (accessType === 'read')	{
					const siblingPermissions = this.formattedPermissions.filter(permission => permission.pageId === pageId)
					siblingPermissions.forEach(siblingPermission => {
						const accessTypeIds = [siblingPermission.fullPermission, siblingPermission.createAndEdit, siblingPermission.delete]
						accessTypeIds.forEach(id =>	{
							if (id != null) this.selectedPermissions = this.selectedPermissions.filter(permission => permission !== id)
						})
					})
				}
				// We also want to disable Full Permission when any sibling permissions are deselected
				if (accessType !== 'fullPermission' || accessType !== 'read') {
					const siblingFullPermission = this.formattedPermissions.find(permission => permission.pageId === pageId && permission.fullPermission)?.fullPermission
					if (siblingFullPermission && this.selectedPermissions.includes(siblingFullPermission)) {
						this.selectedPermissions = this.selectedPermissions.filter(permission => permission !== siblingFullPermission)
					}
				}
			}
			// Unfortunately we've run out of time this release to refactor this code to not cause duplicates, so we'll just remove them here
			const uniqueIds = [...new Set(this.selectedPermissions)]
			this.selectedPermissions = uniqueIds
			this.$emit('update:permissions', this.selectedPermissions)
		},
		rowClass (item) {
			return item.separator ? 'separator' : ''
		}
	}
}
</script>
<style lang="scss">
.separator td {
	background-color: #f5f5f5;
	height: 5px !important;
}
</style>
